<template>
  <div class="integrations-table">

    <!-- Filters -->
    <div class="card p-2 pt-3 bg-light border rounded-0">
      
      <b-row>
        <!-- Left Column -->
        <b-col class="my-1" xl="6" lg="6" md="12" >
          
          <!-- Accounts -->
          <b-form-group
            v-if="userAccounts.length > 1"
            label="Account"
            label-for=""
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-1"
          >
            <multiselect 
              v-model="table_filters.account"
              trackBy="account"
              label="name"
              size="sm"
              :options="userAccounts"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              placeholder="Select to filter..."
              @input="table_filters.currentPage=1; refreshTableData()"
            ></multiselect>
          </b-form-group>

          <!-- From date -->
          <b-form-group
            label="From"
            label-for=""
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-1"
          >
            <b-form-datepicker size="sm" class="border-radius-none" v-model="table_filters.from_date" start-weekday="1" min="2016-01-01" :reset-button="true" locale="en" placeholder="Select to limit" @input="table_filters.currentPage=1; refreshTableData()"></b-form-datepicker>
          </b-form-group>

          <!-- To date -->
          <b-form-group
            label="To"
            label-for=""
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-1"
          >
            <b-form-datepicker size="sm" class="border-radius-none" v-model="table_filters.to_date" start-weekday="1" min="2016-01-01" :reset-button="true" locale="en" placeholder="Select to limit" @input="table_filters.currentPage=1; refreshTableData()"></b-form-datepicker>
          </b-form-group>
        </b-col>
        
        <!-- Right Column -->
        <b-col class="my-1 align-self-end" xl="6" lg="6" md="12">

          <!-- Status -->
          <b-form-group
            label="Status"
            label-for=""
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-1"
          >
            <b-select :options="table_status_filters" v-model="table_filters.status" size="sm" value-field="id" text-field="text" @change="table_filters.currentPage=1; refreshTableData()"/>
          </b-form-group>

          <!-- Search -->
          <b-form-group
            label="Search"
            label-for=""
            label-cols-sm="3"
            label-align-sm="right"
            label-size="sm"
            class="mb-1"
          >
            <b-input-group class="ml-auto">
              <b-input v-model="table_filters.search" size="sm" placeholder="Filter table..." debounce="400" v-on:update="table_filters.currentPage=1; refreshTableData(true)"/>
              <b-input-group-append>
                <b-button size="sm" v-on:click="refreshTableData(true)"><b-icon icon="arrow-clockwise"></b-icon></b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>

        </b-col>
      </b-row>
      
    </div>

    <!-- Table -->
    <b-table
      ref="table"
      responsive
      show-empty
      emptyText="There are no integrations to show"

      :hover="false"
      :outlined="false"
      :borderless="false"

      primary-key="id"
      :items="tabledataProvider"
      :fields="table_fields"
      :tbody-tr-class="rowClass"

      :per-page="table_filters.perPage"
      :current-page="table_filters.currentPage"

      :busy.sync="table_settings.isBusy"
      :no-local-sorting="true"
      :sort-by.sync="table_filters.sortBy"
      :sort-desc.sync="table_filters.sortDesc"
    >
      <!-- custom formatted columns -->
      <template #cell(id)="data">
        <b-link :to="{name:'IntegrationDetails', params: { integration_id: data.item.id }}">{{data.item.id}}</b-link>
      </template>

    </b-table>

    <!-- table pagination -->
    <b-row>
      <b-col class="text-right">
        <b-pagination
          class="d-inline-flex"
          v-model="table_filters.currentPage"
          :total-rows="table_settings.total_rows"
          :per-page="table_filters.perPage"
        >
        </b-pagination>
      </b-col>
    </b-row>
    
  </div>
</template>


<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'IntegrationTable',
  components: {
    //Multiselect,
  },

  props: {
    settings: {
      type: null,
      default: function () {
        return {}
      }
    },
    filters: {
      type: null,
      default: function () {
        return {}
      }
    }
  },

  data() {
    return {
      table_settings: {
        isBusy: false,
        total_rows: 0,
        pageOptions: [10, 20, 30],
      },
      table_filters: { // Specify also here to make it responsive...
        currentPage: 1,
        perPage: 10,
        sortBy: 'created_at',
        sortDesc: true,
        search: '',
        status: null,
        account: null,
      },
      // Table columns - basic definitions - filtered on access before use
      table_fields_template: [
        {
          key: 'id',
          label: 'Id',
          sortable: true,
        },
        {
          access: ['superadmin'],
          key: 'account',
          label: 'Account',
          sortable: false,
        },
        {
          key: 'created_at',
          label: 'Created',
          sortable: true,
        },
        {
          key: 'status',
          label: 'Status',
          sortable: true,
        },
        {
          key: 'name',
          label: 'Name',
          sortable: true,
          thClass: 'd-none d-md-table-cell',
          tdClass: 'd-none d-md-table-cell'
        },
      ],

      // Filter: Status
      table_status_filters: [
        {id: null, text: 'Select to filter...' },
      ],
    }
  },

  async created() {
    let filters = this.getPageSettings( this.$route.name ) // from saved settings
    this.table_settings = Object.assign({}, this.table_settings, this.settings)
    this.table_filters = Object.assign({}, this.table_filters, filters, this.filters) // default <- saved <- props
    this.savePageSettings({id: this.$route.name, settings: this.table_filters }); // Save it back to update from incoming forced args
  },

  computed: {
    ...mapGetters([
      'getPageSettings',
      'timestampAsDate'
    ]),
    ...mapGetters('user', [
      'currentUser',
      'userAccounts',
      'userAccountName',
      'userHasAnyOfRoles',
    ]),
    table_fields() {
      let fields = this.table_fields_template;
      if ( ! this.userHasAnyOfRoles(['superadmin']) ) {
        fields = fields.filter( x => ! x.access || ! x.access.includes('superadmin') );
      }
      return fields;
    }
  },

  methods: {
    ...mapActions([
      'savePageSettings',
    ]),

    ...mapActions('integration', [
      'queryIntegrationsPromise',
    ]),

    refreshTableData() {
      this.$refs.table.refresh()
    },

    tabledataProvider(ctx){
      const queryObject = {
        'account': this.table_filters.account?.account ?? null, // multiselect does only return full selected object
        'status': this.table_filters.status ?? null,
        'search': this.table_filters.search ?? null,
        'from_date': this.table_filters.from_date ?? null,
        'to_date': this.table_filters.to_date ?? null,
        ...ctx,
      };

      // Save filters, instead of with watcher
      this.savePageSettings({id: this.$route.name, settings: this.table_filters });

      const promise = this.queryIntegrationsPromise(queryObject);
      return promise.then(response => {
        this.table_settings.total_rows = response?.data?.total ?? 0;
        return response?.data?.items ?? [];
      });
    },

    rowClass(item, type) {
      if (!item || type !== 'row') return;
      return;
    }
  },
}
</script>

<style lang="scss" scoped>
</style>